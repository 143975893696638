import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { user } from '../models/users.model';
import { first } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  successMsg: string =
    'Thank You for Registering!.We have sent a confirmation email.Please click on link to verify!';

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private _http: HttpClient,
    private cookieService: CookieService
  ) { }

  // Sign up with email/password
  async signUp(details, companyUniqueName, planId, duration, planName) {
    //call api to create tenant & get teanat in response
    let timeDuration = duration != null ? duration : 'monthly';
    let body = {
      tenantName: companyUniqueName,
      tenantId: '',
      tenantDisplayName: details.company,
      planId: planId,
      existCompanyName: details.company.toLowerCase(),
      domain: details.domain
    }

    let planInput = {
      plan_id: planId != null ? planId : environment.defaultPlan,
      duration: timeDuration,
      status: 'created',
      created_at: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString()
    };

    let tenantResult: any = await this.createTenant(body);
    if (tenantResult['status'] == 500) {
      return tenantResult;
    }

    firebase.auth().tenantId = tenantResult.tenantId;
    let result = await firebase
      .auth()
      .createUserWithEmailAndPassword(details.email, details.password);
    this.sendVerificationMail(result.user);

    await this.setUserData(result.user, details);
    await this.setUserPlan(result.user, planInput, planName);
    await this.setSalesforceData(result.user, planName, details, timeDuration);
    this.afAuth.signOut();
    return tenantResult;
  }

  async sendVerificationMail(user) {
    let actionCodeSettings = {
      url: environment.emailUrl,
      handleCodeInApp: false,
    };

    return user.sendEmailVerification(actionCodeSettings).then(function () {
      console.log("email verification sent to user");
    }).catch(function (error) {
      console.log('error ', error);
    })
  }

  setUserData(user: any, details) {
    if (details.full_name.indexOf(' ') == -1) {
      details.first_name = details.full_name;
      details.last_name = '';
    } else {
      details.first_name = details.full_name.split(' ')[0];
      details.last_name = details.full_name.split(' ')[1];
    }

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `${environment.mainCollection}/${user.tenantId}/Users/${user.uid}`
    );

    const userData: user = {
      user_id: user.uid,
      tenant_id: user.tenantId,
      first_name: details.first_name,
      last_name: details.last_name,
      role: 'Admin',
      email: details.email,
      domain: details.domain,
      is_invited: false,
      email_verified: false,
      send_welcome_mail: false,
      status: true,
      walkthrough: false,
      OtherInfo: {
        created_at: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString(),
        updated_at: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString(),
        created_by: user.uid,
        modified_by: user.uid,
      },
    };

    return userRef.set(userData, {
      merge: true,
    });
  }

  setUserPlan(user, input, planName) {
    let name = (planName != null) ? planName : 'Basic';
    const userPlanRef: AngularFirestoreDocument<any> = this.afs.doc(
      `${environment.mainCollection}/${user.tenantId}/UserPayments/${name}`
    );
    userPlanRef.set(input, {
      merge: true,
    });

    let activePlanInput = {
      activePlanInfo: {
        activePlan: name
      }
    }
    const planRef: AngularFirestoreDocument<any> = this.afs.doc(`${environment.tenantCollection}/${user.tenantId}`);
    return planRef.update(activePlanInput);
  }

  /////////////////////////////////......SignIn......./////////////////////////////////////
  async signIn(email: string, password: string) {
    return await this.afAuth.signInWithEmailAndPassword(email, password);
  }

  //////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////............Forgot password...........////////////////////////////////////////
  // Reset password
  async ForgotPassword(passwordResetEmail: string) {
    return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }
  ///////////////////////////////////////////////////////////////////////////////////

  async getUserDetails(collectionName, id) {
    //return await this.afs.collection(collectionName).doc(id).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
    return await this.afs
      .collection(collectionName, (ref) =>
        ref.where(firebase.firestore.FieldPath.documentId(), '==', id).limit(5)
      )
      .valueChanges({ idField: 'id' })
      .pipe(first())
      .toPromise();
  }

  async checkExistCompany(collectionName, field, value) {
    return await this.afs.collection(collectionName, ref => ref.where(field, '==', value)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async createTenant(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let result = await this._http
      .post(`${environment.serviceUrl}/webApi/tenantCreate`, data, httpOptions)
      .toPromise();

    return result;
  }

  async setSalesforceData(details, planName, userDetails, duration) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let data = {
      companyName: details.tenantId,
      fullname: userDetails.full_name,
      email: details.email,
      planName: planName != null ? 'Hireme' + ' ' + planName + ' ' + duration : environment.defaultPlanName
    };
    let result = await this._http
      .post(`${environment.salesforceUrl}/salesforceConnect`, data, httpOptions)
      .toPromise();

    return result;
  }

  async sessionLogin(idToken, tenantId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': "application/json",
        'Content-Type': "application/json",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': '*'
      })
    }
    let data = { idToken, tenantId: tenantId }
    let result = await this._http.post(`${environment.serviceUrl}webApi/sessionLogin`, data, httpOptions).toPromise();
    return result;
  }

  async getCustomToken() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': '*',
        "Cookie": this.cookieService.get('__session')
      }),
    }
    let data = { __session: this.cookieService.get('__session'), tenantId: this.cookieService.get('tenantId') }
    let result = await this._http.post(`${environment.serviceUrl}webApi/checkAuth`, data, httpOptions).toPromise();
    return result;
  }

}
