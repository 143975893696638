<!-- <app-walkthrough *ngIf="showWalkThrough" [userDetails]="userInfo[0]"></app-walkthrough> -->

<div class="fixed-top" *ngIf="login">
  <nav class="navbar navbar-expand-lg navbar-dark bg-blue py-2" aria-label="Main navigation">
    <div class="container-fluid" style="padding-left: 25px; padding-right: 25px;">
      <button class="navbar-toggler p-0 border-0" type="button" data-bs-toggle="offcanvas"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse offcanvas-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item nav-logo" routerLinkActive="active">
            <img [src]="
                companyLogo ? companyLogo : '../../../assets/images/company-logo.svg'
              " onerror="this.src='../../../assets/images/img-logo-symbol.png'" class="img-fluid header-logo" />
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" *ngIf="allowedPermission.includes('dashboard')" aria-current="page"
              href="javascript:void(0)" [routerLink]="['dashboard']">
              Dashboard
            </a>
          </li>
          <li class="nav-item" *ngIf="allowedPermission.includes('jobs')" routerLinkActive="active">
            <a class="nav-link" href="javascript:void(0)" routerLink="jobs">
              Jobs
            </a>
          </li>
          <li class="nav-item" *ngIf="allowedPermission.includes('candidate')" routerLinkActive="active">
            <a class="nav-link" href="javascript:void(0)" routerLink="candidate">
              Candidates
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active" *ngIf="allowedPermission.includes('interviews')">
            <a class="nav-link" href="javascript:void(0)" routerLink="interviews">
              Interviews
            </a>
          </li>
          <li class="nav-item" *ngIf="allowedPermission.includes('clients')" routerLinkActive="active">
            <a class="nav-link" href="javascript:void(0)" routerLink="clients">
              Clients
            </a>
          </li>
          <li class="nav-item" *ngIf="allowedPermission.includes('contacts')" routerLinkActive="active">
            <a class="nav-link" href="javascript:void(0)" routerLink="contacts">
              Contacts
            </a>
          </li>

          <li class="nav-item" *ngIf="allowedPermission.includes('contracts')" routerLinkActive="active">
            <a class="nav-link" href="javascript:void(0)" routerLink="contracts">
              Contracts
            </a>
          </li>

        </ul>
      </div>



      <!---- Trial & Upgrade Label START ---->
      <div class="d-flex">
        <div class="row mx-2" *ngIf="isAdmin">
          <div class="col d-flex align-items-center" *ngIf="totalExpiryRemaining != 0 && planName == ''">
            <div class="nowrap text-white">
              Trial: {{ totalExpiryRemaining }} days remaining
            </div>
          </div>

          <div class="col d-flex align-items-center" *ngIf="planName != ''">
            <div class="nowrap text-white"><a style="color:white" href="javascript:void(0)"
                routerLink="/settings/plans">
                {{ planExpiryMessage }}</a>
            </div>
          </div>

          <div class="col d-flex align-items-center" *ngIf="planName != ''">
            <div class="nowrap text-white">Current Plan: {{ planName }}</div>
          </div>

          <div class="col d-flex align-items-center" *ngIf="planName == '' || planName == 'Basic'">
            <button class="btn btn-primary py-1 upgradeButton" routerLink="/settings/plans">
              Upgrade Now
            </button>
          </div>
        </div>
        <!---- Trial & Upgrade Label END ---->


        <!---- Profile List START ---->
        <div class="nav-item dropdown">
          <a class="nav-link" href="javascript:void(0)" id="navbarDarkDropdownMenuLink" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="{{ userImage }}" onerror="this.src='../../../assets/images/user-thumbnail.png'"
              class="avatar img-fluid rounded-circle" />
          </a>

          <ul class="dropdown-menu dropdown-menu-end py-0 my-1 shadow-sm" aria-labelledby="navbarDarkDropdownMenuLink">
            <li>
              <a class="dropdown-item" href="javascript:void(0)"><span class="dropdown-item px-2">{{ userName }} ({{
                  Role }})</span>({{ companyName }})</a>
            </li>
            <li *ngIf="allowedPermission.includes('settings')">
              <a class="dropdown-item" href="javascript:void(0)" routerLink="settings/profile">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-user">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <span class="px-2">Settings</span></a>
            </li>

            <li *ngIf="allowedPermission.includes('settings')">
              <a class="dropdown-item" href="{{assessmentURL}}" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-user">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <span class="px-2">Create Assessment</span></a>
            </li>

            <li *ngIf="allowedPermission.includes('settings')">
              <a class="dropdown-item" href="{{publicUrl}}" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-user">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <span class="px-2">Career Portal</span></a>
            </li>



            <li *ngIf="allowedPermission.includes('skills')">
              <a class="dropdown-item" href="javascript:void(0)" routerLink="skills">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-user-check">
                  <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="8.5" cy="7" r="4"></circle>
                  <polyline points="17 11 19 13 23 9"></polyline>
                </svg>
                <span class="px-2">Skills</span></a>
            </li>

            <li *ngIf="allowedPermission.includes('user')">
              <a class="dropdown-item" href="javascript:void(0)" routerLink="user">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-user-plus">
                  <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="8.5" cy="7" r="4"></circle>
                  <line x1="20" y1="8" x2="20" y2="14"></line>
                  <line x1="23" y1="11" x2="17" y2="11"></line>
                </svg>
                <span class="px-2">Add User</span></a>
            </li>
            <li>
              <a class="dropdown-item" href="javascript:void(0)" (click)="signOut()"><svg
                  xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                  class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fill-rule="evenodd"
                    d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                <span class="px-2">Sign Out</span></a>
            </li>
          </ul>
        </div>
        <!---- Profile List END ---->

      </div>
    </div>
  </nav>
</div>