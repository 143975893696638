// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyC9LAu0qv2gAHMPiIdPLqUA9cqQBVydz6c",
    authDomain: "recruitment-tracking-app-dev.firebaseapp.com",
    projectId: "recruitment-tracking-app-dev",
    storageBucket: "recruitment-tracking-app-dev.appspot.com",
    messagingSenderId: "578145829417",
    appId: "1:578145829417:web:8eca648029ebc9364e1238",
    measurementId: "G-M074N7NKBD"
  },
  emailUrl: 'https://dev.hireme.cloud',
    adminRight: ['Admin', 'HRManager', 'HRExecutive'],
    serviceUrl: 'https://us-central1-recruitment-tracking-app-dev.cloudfunctions.net/',
    paymentUrl: 'https://qiehe68t10.execute-api.us-east-1.amazonaws.com/dev/api/v1',
    msApi: 'https://graph.microsoft.com/v1.0/drive/root:',
    oneDriveFolder: 'HireMe',
    senderMail: 'no-reply@hireme.cloud',
    mainCollection: 'HiremeApp',
    tenantCollection: 'HiremeTenants',
    configCollection: 'HiremeConfig',
    rolesAllowedToHRManager: ['HRManager', 'HRExecutive', 'Interviewer'],
    rolesAllowedToHRExecutive: ['HRExecutive', 'Interviewer'],
    interviewersRole: ['Interviewer'],
    razorPayId: 'rzp_test_07ql20L4zT9Rrn', // rzp_live_0cLTyZZQaPR8eI
    defaultPlan: 'plan_MWBSaxA4Ne7uA13zqjxS',
    defaultPlanName: "Hireme Basic Monthly",
    salesforceUrl: 'https://iejigzq15h.execute-api.us-east-1.amazonaws.com/dev',
    domain: '.hireme.cloud',
    allowOrigin: 'https://dev.hireme.cloud',//'/https://locahost:4200'
    msLoginClientId: '38285964-a8a5-4a4b-95bd-770de1f08c60',
    msLoginRedirectUrl: 'https://dev.hireme.cloud',
    parseToken: '89bdec5cc7b3e9e73f9b5475124b194e'
};

/*

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
