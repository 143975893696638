import { Injectable } from '@angular/core';
import {
  Router, CanActivate, ActivatedRouteSnapshot
} from '@angular/router';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import firebase from "firebase/app";
import { environment } from 'src/environments/environment';


@Injectable()
export class RoleService implements CanActivate {
  permission: any;
  constructor(public router: Router, public loginservice: LoginService, private toastr: ToastrService, private cookieService: CookieService) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const currentRole = JSON.parse(sessionStorage.getItem('userInfo'));
    if (currentRole != null) {
      const allowedRole = currentRole[0].role;
      const allowedPath = route.data.path;
      const permissionCheck = await this.menuAllowed(allowedRole);
      const allowed = permissionCheck.allowedMenu;
      if (allowed.includes(allowedPath) || (allowedPath == 'candidate')) {
        return true;
      } else {
        return false;
      }
    } else {
      this.router.navigate(['signin']);
    }
  }


  async menuAllowed(menu): Promise<any> {
    switch (menu) {
      case 'Admin':
        return { allowedMenu: ['dashboard', 'jobs', 'candidate', 'interviews', 'clients', 'contacts', 'contracts', 'settings', 'skills', 'user', 'pipeline'] };

      case 'HRManager':
        return { allowedMenu: ['dashboard', 'jobs', 'candidate', 'interviews', 'clients', 'contacts', 'contracts', 'settings', 'skills', 'user', 'pipeline'] };

      case 'HRExecutive':
        return { allowedMenu: ['dashboard', 'jobs', 'candidate', 'interviews', 'clients', 'contacts', 'contracts', 'settings', 'skills', 'user', 'pipeline'] };

      case 'Interviewer':
        return { allowedMenu: ['interviews', 'settings'] };

      case 'Candidate':
        return { allowedMenu: ['employee', 'applies', 'my-interviews', 'job-openings', 'candidate-settings', 'my-profile', 'home', 'my-quizzes'] };

    }
  }


  async checkAuthentication(tenantId) {
    try {
      let auth = firebase.auth();
      auth.tenantId = tenantId;
      let result: any = await this.loginservice.getCustomToken()
      if (result.status == 'success') {
        let loginResponse: any = await firebase.auth().signInWithCustomToken(result.customToken);
        let User = await this.loginservice.getUserDetails(`${environment.mainCollection}/${this.cookieService.get('tenantId')}/Users`, loginResponse.user.uid);
        sessionStorage.setItem('userInfo', JSON.stringify(User))
        return true;
      } else {
        return false;
      }

    } catch (error) {
      //console.log('err ', error);
      //this.toastr.error(error.code);
      return false;
    }

  }
}
